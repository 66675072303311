import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HubImage, ImageService } from './services/image.service';
import { ImageEditorComponent } from './components/image-editor/image-editor.component';
import { Subscription, concatMap, delay, from, lastValueFrom, of, timeout } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from './services/modal.service';
import { environment } from 'src/environments/environment';
import { UserActionService } from '../shared/services/user-action.service';
import { ClientType, MessageType } from '../utils/const';
import { MeesmaService } from '../shared/services/meesma.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../shared/services/app.service';
import { base64ToBlob, blobToBase64, urlToBase64 } from '../utils/image';
import { ObjectUrlService } from './services/object-url.service';

@Component({
  selector: 'app-proposer',
  templateUrl: './proposer.component.html',
  styleUrls: ['./proposer.component.css']
})
export class ProposerComponent implements AfterViewInit, OnDestroy, OnInit {
  imageData!: string
  uuid!: string
  croppedImageData!: string
  currentFilter = 0
  isPrint = false
  isRecommend = false
  isFace = false
  isLoading = false
  loadingText = ''
  reloadFlag = false
  returnPath = 'doctor/linked_devices/proposer_return'
  client!: string
  translations: { [key: string]: string } = {}
  totalChunks: number = 0
  isReceivingChunks = true
  waitForChunkTimeOut!: NodeJS.Timeout

  private _originalImage!: string
  private _tempChunks: string[] = []

  @ViewChild('imageEditor') imageEditor!: ImageEditorComponent
  private _onLangChangeSub!: Subscription;

  constructor(private spinner: NgxSpinnerService, private _modalService: ModalService,
    private _router: Router, private _imageService: ImageService,
    private _route: ActivatedRoute, private _userActionService: UserActionService,
    private _meesmaService: MeesmaService, private _appService: AppService,
    private translate: TranslateService, private objectUrlService: ObjectUrlService,
    private _cdr: ChangeDetectorRef
  ) {
    this.translate.get(["proposer.imageSavedSuccessfully",
      "proposer.saveEditedImageConfirmation",
      "shared.no",
      "shared.yes",
      "shared.confirm",
      "proposer.serverError",
      "proposer.sessionExpired",
      "proposer.imageNotFound",
      "proposer.unknownError"
    ]).subscribe((translations) => {
      this.translations = translations
    })
  }
  ngOnInit(): void {
    this.client = this._appService.getClientType();
    this._userActionService.announceClientType(this.client);
    this._onLangChangeSub = this.translate.onLangChange.subscribe(() => {
      this.translate.get(["proposer.imageSavedSuccessfully",
        "proposer.saveEditedImageConfirmation",
        "shared.no",
        "shared.yes",
        "shared.confirm",
        "proposer.serverError",
        "proposer.sessionExpired",
        "proposer.imageNotFound",
        "proposer.unknownError"
      ]).subscribe((translations) => {
        this.translations = translations
      })
    });

    (window as any).sendMessageToProposer = this.sendMessageToProposer.bind(this)
  }
  ngOnDestroy(): void {
    if (this._onLangChangeSub)
      this._onLangChangeSub.unsubscribe()
  }
  ngAfterViewInit(): void {
    // this._originalImage = this.imageData = "/assets/img/pola2.jpeg"
    this._getImage();
  }

  // event method
  async onSaveBack() {
    const isSaved = this._shouldSave ? await this._modalService.openConfirm(
      this.translations["shared.confirm"],
      this.translations["proposer.saveEditedImageConfirmation"],
      this.translations["shared.yes"],
      this.translations["shared.no"]) : false;
    if(this.client == ClientType.MEESMA) {
      this.saveToMeesma(isSaved);
    } else {
      this.saveToHub(isSaved);
    }
  }

  async saveToMeesma(isSaved: boolean) {
    this.spinner.show('save-spinner');
    if(this.isWebViewAvailable()) {
      try {
        if(isSaved) {
          const base64 = await blobToBase64(await this.imageEditor.getCroppedImage(true));
          this.sendImageToMeesmaInChunks(base64, 500000);
        } else {
          this.sendMessageToMeesma(MessageType.EDITED, null);
        }
      } catch (err: any) {
        this._modalService.openNotification(err.message);
        this.spinner.hide('save-spinner');
      }
    } else {
      this.spinner.hide('save-spinner');
      this._modalService.openNotification("Your device is not supported");
    }
  }

  sendImageToMeesmaInChunks(base64: string, chunkSize: number) {
    const cleanBase64Data = base64.replace(/^data:image\/(png|jpeg);base64,/, ''); // Clean data URL if present
    const chunks = [];

    for (let i = 0; i < cleanBase64Data.length; i += chunkSize) {
      chunks.push(cleanBase64Data.slice(i, i + chunkSize));
    }
    from(chunks)
    .pipe(
      concatMap((chunk, index) => 
        of({ chunk, index }).pipe(delay(300))
      )
    )
    .subscribe({
      next: ({ chunk, index }) => {
        this.sendMessageToMeesma(MessageType.EDITED, {
          chunk: chunk,
          index: index,
          isLastChunk: index === chunks.length - 1 // Determine if this is the last chunk
        });
        this._userActionService.announceImageSavingPercent(Math.round((index + 1) * 100 / chunks.length));
      },
      complete: () => {
        this._userActionService.announceImageSavingPercent(0);
        this._meesmaService.clearSession();
        this.spinner.hide('save-spinner');
      },
    });
  }

  async toBuffer(base64: string) {
    return (await (await fetch(base64)).blob()).arrayBuffer();
  }

  async saveToHub(isSaved: boolean) {
    if (!isSaved) {
      return window.location.replace(`${environment.domain}/${this.returnPath}?uuid=${this.uuid}`);
    }
    const token = sessionStorage.getItem('hub-token');
    if (!token) {
      this._router.navigate(['unauthorized']);
      return;
    }
    this.spinner.show('save-spinner')
    const blob = await this.imageEditor.getCroppedImage(true);
    const file: File = new File([blob], 'editedImage', { type: blob.type });
    this._imageService.uploadImage(token, file, this._image)
      .then((res) => {
        window.location.replace(`${environment.domain}/${this.returnPath}?uuid=${res.uuid}`);
      }).catch(() => {
        this._modalService.openNotification(this.translations["proposer.serverError"])
      }).finally(() => {
        this.spinner.hide('save-spinner')
      });
  }

  onReloadImage() {
    this._getImage();
  }

  reloadComponent(data: boolean) {
    this.reloadFlag = data;
  }

  onPrintClosed(): void {
    this.isPrint = false;
  }

  onFaceSimulation() {
    this._userActionService.announceFaceImage(this._originalImage);
    this.isFace = true;
    this._isDrawing = false;
    this.isRecommend = false;
  }

  sendMessageToProposer(type: string, data: any): any {
    switch(type) {
      case MessageType.ORIGINAL:
        return this.reassembleImage(data);
      default:
        break
    }
  }
  // Private
  private _image!: HubImage;
  public get image(): HubImage {
    return this._image;
  }
  public set image(value: HubImage) {
    this._image = value;
  }
  public get isHub() {
    return this.client === ClientType.HUB;
  }
  private _isDrawing = true;
  public get isDrawing(): boolean {
    return this._isDrawing;
  }
  public set isDrawing(value: boolean) {
    this._isDrawing = value;
    if (!value) {
      this.spinner.show('move-spinner')
      setTimeout(async () => {
        this.croppedImageData = this._shouldSave ? await blobToBase64(await this.imageEditor.getCroppedImage(false)) : this.imageData;
        this.spinner.hide('move-spinner')
      }, 100);
    }
  }

  private get _shouldSave() {
    return this.imageEditor?.hasChanged() || this._originalImage != this.imageData
  }

  private _getImage() {
    this.spinner.show('filter-spinner')
    if(this.client == ClientType.MEESMA) {
      if(this.isWebViewAvailable()) {
        setTimeout(() => this.sendMessageToMeesma(MessageType.READY, null), 1000);
        this._initTimeoutForChunk(30000);
      } else {
        this._getMeesmaImage();
      }
    } else {
      this._getHubImage();
    }
  }

  private _getMeesmaImage() {
    this._meesmaService.emit(MessageType.READY, { room: this._meesmaService.room });
    this._meesmaService.on("message", (msg) => {
      if(msg.type != MessageType.ORIGINAL) return;
      this._originalImage = this.imageData = msg.data.image;
      this.imageEditor.isError = false;
      this._meesmaService.off("message");
    })
  }

  private _getHubImage() {
    lastValueFrom(this._imageService.getImage(sessionStorage.getItem('hub-token') || '', this._route.snapshot.queryParams['uuid']))
      .then(res => {
        this.image = res
        this.uuid = this._image.uuid
        this._imageService.loadImageWithProgress(this._image.file_url)
          .subscribe({
            next: (result) => {
              this._userActionService.announceImageLoadingPercent(result.percent);
              if(result.blob) {
                this._originalImage = this.imageData = this.objectUrlService.createObjectUrl(result.blob);
                this._userActionService.announceImageLoadingPercent(0);
              }
            },
            error: (err) => { 
              this._modalService.openNotification(err.message);
              this._userActionService.announceImageLoadingPercent(0);
              this.spinner.hide('filter-spinner');
            },
            complete: () => {
              this.spinner.hide('filter-spinner');
            }
          });
        this.imageEditor.isError = false
      })
      .catch(err => {
        this.spinner.hide('filter-spinner')
        switch (err.status) {
          case 403: {
            this._modalService.openNotification(this.translations["proposer.sessionExpired"])
            break;
          }
          case 404: {
            this._modalService.openNotification(this.translations["proposer.imageNotFound"])
            break;
          }
          default: {
            this._modalService.openNotification(this.translations["proposer.unknownError"])
            this.imageEditor.isError = true
            break;
          }
        }
      })
  }

  sendMessageToMeesma(type: string, data: any) {
    (window as any).webkit.messageHandlers.messageHandlerForMeesma.postMessage({ type: type, data: data });
  }

  isWebViewAvailable() {
    return (window as any).webkit && (window as any).webkit.messageHandlers
      && (window as any).webkit.messageHandlers.messageHandlerForMeesma;
  }

  reassembleImage(data: any): any {
    clearTimeout(this.waitForChunkTimeOut);
    if(data.image) {
      const blob = base64ToBlob(data.image);
      this._originalImage = this.imageData = this.objectUrlService.createObjectUrl(blob);
      return 1;
    }
    if(!this.isReceivingChunks) return 1;
    const { chunk, index, totalChunks, length } = data
    if(chunk && length && index != null && totalChunks) {
      if(chunk.length != length) {
        this._handleChunkFailed(`Failed due to truncated data at ${index}. Received ${chunk.length} out of ${length}`);
        return 0;
      }
      this._handleChunkSuccess(chunk, index * 1, totalChunks * 1);
      return 1;
    } else {
      this._handleChunkFailed('Wrong message structure');
      return 0;
    }
  }

  private _handleChunkFailed(message: string) {
    this.isReceivingChunks = false;
    console.log(message);
    this._modalService.openNotification(message).then(() => {
      this.sendMessageToMeesma(MessageType.READY, null);
    });
  }

  private _handleChunkSuccess(chunk: string, index: number, total: number) {
    this._tempChunks.push(chunk);
    const percent = Math.round(((index + 1) * 100) / total);
    this._userActionService.announceImageLoadingPercent(percent);
    console.log(`Received chunk index ${index} of ${total} at ${new Date().toISOString()}. ${percent}%`);
    if(index == total - 1) {
      console.log("Received all chunks at ", new Date().toISOString());
      const fullString = this._tempChunks.join("");
      console.log(`Total length ${fullString.length}. at ${new Date().toISOString()}`)
      this._originalImage = this.imageData = this.objectUrlService.createObjectUrl(base64ToBlob(fullString));
      this._cdr.detectChanges();
      console.log(`Merged chunks at ${new Date().toISOString()}`);
      this._tempChunks = [];
      this.isReceivingChunks = false;
    } else {
      this._initTimeoutForChunk(10000);
    }
  }

  private _initTimeoutForChunk(timeout: number = 5000) {
    this.waitForChunkTimeOut = setTimeout(() => this._handleChunkTimeout(), timeout);
  }

  private _handleChunkTimeout() {
    this.isReceivingChunks = false;
    this._handleChunkFailed("Timeout! Proposer was waiting too long.");
  }
}
