import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TranslationMessagesService } from './shared/services/translation-messages.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {
  translationSubscription!: Subscription;
  _onLangChangeSub!: Subscription;
  constructor(private _translate: TranslateService, private _translationMessageService: TranslationMessagesService, private router: Router) {
    this.detectLang()
    this._translate.get("appTitle").subscribe((value: string) => {
      this.title = value;
    })
  }
  ngOnDestroy(): void {
    if(this.translationSubscription)
      this.translationSubscription.unsubscribe()
  }
  ngOnInit(): void {
    this.translationSubscription = this._translationMessageService.modeMessages$.subscribe((value) => {
      this._translate.use(value);
    })
    this._translate.onLangChange.subscribe(() => {
      this._translate.get("appTitle").subscribe((value: string) => {
        this.title = value;
      })
    })
    console.log("Hello");
  }
  private detectLang(){
    const match = this.router.url.match(/locale=([a-zA-z-]+)/)
    let langCode = 'en';
    if(match){
      langCode = match[1].split('-')[0];

    }else{
      langCode = navigator.language.split('-')[0]
    }
    const langExisted = ['en', 'de', 'uk', 'ru', 'es', 'fr'].some(lang => lang === langCode);
    if(!langExisted){
        langCode = 'en';
    }
    this._translate.setDefaultLang(langCode)
  }

  title = "Aesthetics Recommendation";


}

