import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MODE_BUTTONS } from '../../../utils/items';
import packageInfo from 'package.json'
import { DataService } from '../../services/data.service';
import { UserActionService } from 'src/app/shared/services/user-action.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationMessagesService } from 'src/app/shared/services/translation-messages.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit, OnDestroy {
  // propeties
  languages = [
    { code: 'de', name: 'Deutsch' },        // German
    { code: 'fr', name: 'Français' },       // French
    { code: 'es', name: 'Español' },        // Spanish
    { code: 'ru', name: 'Русский' },        // Russian
    { code: 'uk', name: 'Українська' },     // Ukrainian
    { code: 'en', name: 'English' },        // English
    { code: 'tr', name: 'Türkçe' },         // Turkish
    { code: 'pt-BR', name: 'Português (BR)' }, // Portuguese (Brazil)
    { code: 'it', name: 'Italiano' }        // Italian
  ];

  selectedLanguage!: string;
  title = "Drawings"
  imageSavingPercent: number = 0

  filterButtons = MODE_BUTTONS
  private _onLangChangeSub!: Subscription;
  @Input() set currentFilter(value: number) {
    if(this._currentFilter === value) return;
    this._currentFilter = value;
    this.onFilterChoose(value);
  }
  @Input()  isDrawing = true;
  // output
  @Output() currentFilterChange: EventEmitter<number> = new EventEmitter<number>();
  fversion = packageInfo.version
  bversion!: string
  zoom = 100
  // private
  private _currentFilter!: number
  // event method
  constructor(private _dataService: DataService,
     private _userAction: UserActionService,
     private _translate: TranslateService,
     private _translationModeMessageService: TranslationMessagesService,
     private router: Router
    ) {
    this.detectLang();
    this._translate.get(['item.evenness',
      'item.rednessV2',
      'item.redness',
      'item.pigmentation',
      'item.original']).subscribe((translations) => {
      this.filterButtons[0].name = translations['item.original']
      this.filterButtons[1].name = translations['item.pigmentation']
      this.filterButtons[2].name = translations['item.redness']
      // this.filterButtons[3].name = translations['item.rednessV2']
      this.filterButtons[3].name = translations['item.evenness']
    })
  }
  ngOnDestroy(): void {
    if(this._onLangChangeSub){
      this,this._onLangChangeSub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this._userAction.imageSavingPercent$.subscribe(val => this.imageSavingPercent = val);
    this._dataService.getVersion().subscribe((val => { console.log(val);this.bversion = val }))
    this._userAction.zoomChange$.subscribe(val => {
      this.zoom = val;
    })
    this._userAction.titleChange$.subscribe(val => {
      this.title = val;
    })
    this._onLangChangeSub = this._translate.onLangChange.subscribe(() => {
      this._translate.get(['item.evenness',
        'item.rednessV2',
        'item.redness',
        'item.pigmentation',
        'item.original']).subscribe((translations) => {
        this.filterButtons[0].name = translations['item.original']
        this.filterButtons[1].name = translations['item.pigmentation']
        this.filterButtons[2].name = translations['item.redness']
        // this.filterButtons[3].name = translations['item.rednessV2']
        this.filterButtons[3].name = translations['item.evenness']
      })
    })
  }

  onLanguageChange(languageCode: any) {
    this.selectedLanguage = languageCode.code;
    this._translationModeMessageService.sendModeMessages(languageCode.code);
    this._translate.use(languageCode.code);
  }

  private detectLang(){
    const match = this.router.url.match(/locale=([a-zA-z-]+)/)
    let langCode = 'en';
    if(match){
      langCode = match[1].split('-')[0];

    }else{
      langCode = navigator.language.split('-')[0]
    }
    const langExisted = this.languages.some(lang => lang.code === langCode);
    if(!langExisted){
        langCode = 'en';
    }
    this.selectedLanguage = langCode;
    this._translate.setDefaultLang(langCode)
  }
  onFitScreen() {
    this._userAction.announceFitScreen(true)
    this.zoom = 100
  }

  onFilterChoose(index: number): void {
    this.filterButtons.forEach((button, i) => {
      if(index === i) {
        button.isActive = true;
        return;
      }
      button.isActive = false;
    });
    this._userAction.announceFilterMode(index);
  }

  get filterMode() {
    return this.filterButtons.findIndex(mode => mode.isActive);
  }
  // private method
}
